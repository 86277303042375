<template>
  <div class="nofund">404</div>
</template>
<script>
export default {
  data: () => ({})
}
</script>
<style >
.nofund {
  font-size: 30px;
  font-weight: 900;
  color: red;
}
</style>
